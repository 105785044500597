import React from 'react';
import { Button, Alert, Row, Col, Form, Table, InputGroup } from 'react-bootstrap';
import { Auth, API } from "aws-amplify";
import moment from 'moment';
import { Locations } from "../components/contstants/Locations";

export default class ModifyStudentData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            filteredStudents: [],
            selectedStudent: {},
            editedStudent: {},
            avaliableOptions: [],
            loadedData: false,
            fName: "",
            lName: "",
            date: "",
            yearOptions: ["2019", "2018"],
            filterYear: "",
            locations: Locations,
            cols: [
                {
                    Header: 'Date',
                    accessor: 'date'
                }, {
                    Header: 'First Name',
                    accessor: 'fName'
                }, {
                    Header: 'Last Name',
                    accessor: 'lName'
                }]
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStudentEdit = this.handleStudentEdit.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.findStudents = this.findStudents.bind(this);
    }

    componentDidMount() {
        const that = this;
        var currentYear = moment().add("1", "year").year();
        var yearOptions = [];
        yearOptions.push("All Years");


        while (currentYear > 2022) {
            yearOptions.push(currentYear + "");
            currentYear--;
        }

        this.setState({ yearOptions, filterYear: moment().format("YYYY") });
        fetch('https://s3.amazonaws.com/publicdates/roadTestPackages.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                that.setState({ avaliableOptions: data });
            });
    }

    handleChange(e) {
        var key = e.target.id;
        this.setState({ [key]: e.target.value });
    }

    onRadioPackageChange(e) {
        var editedStudent = this.state.editedStudent;
        editedStudent.selectedPackage = e.currentTarget.id;
        this.setState({
            editedStudent
        });
    }

    onLocationChange(location) {
        var editedStudent = this.state.editedStudent;
        editedStudent.location = location;
        this.setState({
            editedStudent
        });
    }

    onCertificateChange(status) {
        var editedStudent = this.state.editedStudent;
        editedStudent.certPaymentStatus = !status;
        this.setState({
            editedStudent
        });
    }

    handleEditChange = e => {
        console.log(e);
        const { name, value } = e.target;
        var editedStudent = this.state.editedStudent;
        editedStudent[name] = value;
        this.setState({
            editedStudent: editedStudent
        });
    };

    //Update driving school
    handleParkwayUpdate = () => {
        var editedStudent = this.state.editedStudent;
        editedStudent.drivingSchool = "Parkway Driving School";
        this.setState({
            editedStudent: editedStudent
        });
    };

    handleStudentEdit(index) {
        let selectedStudent = this.state.filteredStudents[index];
        console.log(selectedStudent);
        this.setState({ selectedStudent: selectedStudent, editedStudent: JSON.parse(JSON.stringify(selectedStudent)) });
    }

    findStudents = async () => {
        this.getFilteredStudents();
    };

    resetFilters = async () => {
        this.setState({
            fName: "",
            lName: "",
            date: "",
            filterYear: "All Years",
            selectedStudent: {}
        });
    };


    getFilteredStudents = async () => {
        let filters = {};
        if (moment(this.state.date, 'YYYY-MM-DD', true).isValid()) {
            filters.date = moment(this.state.date).format('YYYY-MM-DD');
        }
        if (this.state.fName && this.state.fName.length > 0) {
            filters.fName = this.state.fName;
        }
        if (this.state.lName && this.state.lName.length > 0) {
            filters.lName = this.state.lName;
        }

        if (this.state.filterYear && this.state.filterYear != "All Years") {
            filters.filterYear = this.state.filterYear;
        }

        if (Object.keys(filters).length == 0) {
            this.setState({ loading: false, dateError: "You must specify a filter." });
            return;
        }

        var that = this;
        that.setState({ loading: true });
        let session = await Auth.currentSession();
        let options = {
            body: filters,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        try {
            let data = await API.post("APIGateway", "/v2getStudentsByFilter", options);
            that.setState({ data: data, loading: false, loadedData: true, filteredStudents: data, refreshStatus: "Successfully fetched data! " + data.length + " results found.", dateError: "" });

        } catch (error) {
            console.log(error);
            that.setState({ loading: false, dateError: "There was an error viewing the student table. Please copy this message and send it to your developer for debugging." + JSON.stringify(error) });

        }

    };

    handleUpdateUser = async (event) => {
        this.setState({ loading: true });
        event.preventDefault();
        event.stopPropagation();

        let oldStudent = this.state.selectedStudent;
        let student = this.state.editedStudent;

        if (!moment(student.date, 'YYYY-MM-DD', true).isValid()) {
            this.setState({ moveError: "Invalid Date Entry.", loading: false });
            return;
        }
        var mode = "updateStudent2";
        if (oldStudent.date != student.date) {
            mode = "updateAndDeleteOld";
            //date is being changed, so we need to update the email data to clear out any potential emails sent.
            if (student.emailInitalState !== "Not Sent") {
                student.emailInitalState = "Not Sent";
            }
            if (student.emailRoadTestPassedState !== "Not Sent") {
                student.emailRoadTestPassedState = "Not Sent";
            }
            if (student.emailRoadTestSlotConfirmed !== "Email Not Sent") {
                student.emailRoadTestSlotConfirmed = "Email Not Sent";
            }
            if (student.emailRoadTestSlotState !== "Not Sent") {
                student.emailRoadTestSlotState = "Not Sent";
            }
            if (student.hasOwnProperty("validateToken")) {
                delete student.validateToken;
            }
            if (student.hasOwnProperty("slot")) {
                delete student.slot;
            }
            if (student.hasOwnProperty("weekendDate")) {
                delete student.weekendDate;
            }
        }

        var item = { "mode": mode, "student": student, "newDate": student.date, "oldDate": oldStudent.date };
        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: [item],
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    that.setState({ editedStudent: {}, selectedStudent: {}, loading: false, data: [], filteredStudents: [] }, () => that.findStudents());
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                that.setState({ loading: false });
            });
    };

    render() {
        return (

            <div className="holder">
                <h3>Find and Modify Student Record</h3>
                {this.state.dateError ? <Alert variant="warning">
                    <strong>{this.state.dateError}</strong>
                </Alert> : null}
                {this.state.refreshStatus ? <Alert variant="success">
                    <strong>{this.state.refreshStatus}</strong>
                </Alert> : null}
                <div>
                    <br></br>
                    <h4>Enter Filters</h4>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Label>Select Year to Filter</Form.Label>
                                <Form.Control
                                    name="filterYear"
                                    as="select"
                                    id="filterYear"
                                    onChange={this.handleChange}
                                    value={this.state.filterYear}
                                >
                                    {this.state.yearOptions.map(year => {
                                        return (
                                            <option key={year} value={year}>{year}</option>
                                        );

                                    })}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>Date</Form.Label>
                                <Form.Control placeholder="MM/DD/YYYY" value={this.state.date} onChange={this.handleChange} id="date" type="date" />
                            </Col>
                            <Col>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control placeholder="First name" value={this.state.fName} onChange={this.handleChange} id="fName" type="text" />
                            </Col>
                            <Col>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control placeholder="Last name" value={this.state.lName} onChange={this.handleChange} id="lName" type="text" />
                            </Col>
                        </Row>
                    </Form>
                    <br></br>

                    <Button className="butPadding" disabled={this.state.loading} onClick={this.findStudents}> {this.state.loading ? "Loading..." : "Find students"}</Button>
                    <Button className="butPadding" disabled={this.state.loading} onClick={this.resetFilters} variant={"secondary"}>Reset Filters</Button>

                    <br></br>
                    <br></br>

                    {this.state.filteredStudents.length > 0 ?
                        <Table striped bordered hover responsive size="sm">
                            <thead>
                                <tr className="d-flex">
                                    <th className="col-2">Date</th>
                                    <th className="col-2">First Name</th>
                                    <th className="col-2">Last Name</th>
                                    <th className="col-2">Parent Email</th>
                                    <th className="col-3">Lessons</th>
                                    <th className="col-1">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.filteredStudents.map((x, index) => {
                                    return (
                                        <span>
                                            <tr key={index} className="d-flex">
                                                <td className="col-2">{x.date}</td>
                                                <td className="col-2">{x.fName}</td>
                                                <td className="col-2">{x.lName}</td>
                                                <td className="col-2">{x.parentEmailAddress}</td>
                                                <td className="col-3">
                                                    {x.selectedPackage.includes("Lesson") ?
                                                        <div>
                                                            {x.scheduledLessons && x.scheduledLessons.length > 0 ?
                                                                <div>
                                                                    {x.scheduledLessons.map(lesson => {
                                                                        return (
                                                                            <div>
                                                                                {moment(lesson.date).format("MM/DD/YYYY") + " at " + lesson.slotName + " in " + lesson.location + " with " + lesson.instructor}
                                                                            </div>
                                                                        );
                                                                    })}

                                                                </div> : "No Lessons Scheduled "}

                                                        </div>
                                                        : "N/A"}

                                                </td>
                                                <td className="col-1"><Button onClick={() => this.handleStudentEdit(index)}>Edit</Button></td>
                                            </tr>
                                            {Object.keys(this.state.selectedStudent).length > 0 && this.state.selectedStudent == x ? <div>                    {Object.keys(this.state.selectedStudent).length > 0 ?
                                                <div>
                                                    <hr></hr>
                                                    <Form noValidate onSubmit={this.handleUpdateUser} >
                                                        <Form.Group controlId="date">
                                                            <Form.Label>Road Test Date</Form.Label>
                                                            <Form.Control required
                                                                name="date"
                                                                type="date"
                                                                value={this.state.editedStudent.date}
                                                                placeholder="MM/DD/YYYY"
                                                                onChange={this.handleEditChange}
                                                            />
                                                            <Form.Control.Feedback type="invalid">Please input a permit issue date.</Form.Control.Feedback>

                                                        </Form.Group>
                                                        <Form.Group controlId="location">
                                                            <Form.Label>Road Test Location</Form.Label>
                                                            {this.state.locations.map((location) => {
                                                                return (
                                                                    <Form.Check
                                                                        checked={location === this.state.editedStudent.location}
                                                                        name="location"
                                                                        type="radio"
                                                                        id={location}
                                                                        onChange={() => this.onLocationChange(location)}
                                                                        label={location}
                                                                    />
                                                                );

                                                            })}
                                                            <Form.Control.Feedback type="invalid">Please select a location.</Form.Control.Feedback>

                                                        </Form.Group>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                name="drivingSchool"
                                                                type="text"
                                                                value={this.state.editedStudent.drivingSchool}
                                                                placeholder="Enter Driving School Name (Optional)"
                                                                onChange={this.handleEditChange}
                                                            />
                                                            <InputGroup.Append>
                                                                <Button variant="outline-secondary" onClick={() => this.handleParkwayUpdate()}>Parkway</Button>
                                                            </InputGroup.Append>
                                                        </InputGroup>

                                                        <Row>
                                                            <Col>
                                                                <Form.Label>First Name</Form.Label>
                                                                <Form.Control required
                                                                    name="fName"
                                                                    type="text"
                                                                    value={this.state.editedStudent.fName}
                                                                    placeholder="First Name"
                                                                    onChange={this.handleEditChange}
                                                                />
                                                                <Form.Control.Feedback type="invalid">Please input a name.</Form.Control.Feedback>
                                                            </Col>
                                                            <Col>
                                                                <Form.Label>Last Name</Form.Label>
                                                                <Form.Control required
                                                                    name="lName"
                                                                    type="text"
                                                                    value={this.state.editedStudent.lName}
                                                                    placeholder="Last Name"
                                                                    onChange={this.handleEditChange}
                                                                />
                                                                <Form.Control.Feedback type="invalid">Please input a name.</Form.Control.Feedback>
                                                            </Col>
                                                        </Row>
                                                        <Form.Group controlId="dob">
                                                            <Form.Label>Date of Birth</Form.Label>
                                                            <Form.Control required
                                                                name="dob"
                                                                type="date"
                                                                value={this.state.editedStudent.dob}
                                                                placeholder="MM/DD/YYYY"
                                                                onChange={this.handleEditChange}
                                                            />
                                                            <Form.Control.Feedback type="invalid">Please input a DOB.</Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Row>
                                                            <Col>
                                                                <Form.Group controlId="permitNo">
                                                                    <Form.Label>Permit Number</Form.Label>
                                                                    <Form.Control required
                                                                        name="permitNo"
                                                                        type="text"
                                                                        value={this.state.editedStudent.permitNo}
                                                                        placeholder="Enter text"
                                                                        onChange={this.handleEditChange}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Control.Feedback type="invalid">Please input a permit number.</Form.Control.Feedback>

                                                            </Col>
                                                            <Col>
                                                                <Form.Group controlId="permitDate">
                                                                    <Form.Label>Permit Issue Date</Form.Label>
                                                                    <Form.Control required
                                                                        name="permitDate"
                                                                        type="date"
                                                                        value={this.state.editedStudent.permitDate}
                                                                        placeholder="MM/DD/YYYY"
                                                                        onChange={this.handleEditChange}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">Please input a permit issue date.</Form.Control.Feedback>

                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <Form.Group controlId="emailAddress">
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control required
                                                                name="emailAddress"
                                                                type="email"
                                                                value={this.state.editedStudent.emailAddress}
                                                                placeholder="Enter text"
                                                                onChange={this.handleEditChange}
                                                                autoComplete="email"
                                                            />
                                                            <Form.Control.Feedback type="invalid">Please input a email address.</Form.Control.Feedback>

                                                        </Form.Group>
                                                        <Form.Group controlId="parentEmailAddress">
                                                            <Form.Label>Parent Email</Form.Label>
                                                            <Form.Control
                                                                name="parentEmailAddress"
                                                                type="email"
                                                                value={this.state.editedStudent.parentEmailAddress || ""}
                                                                placeholder="Enter text"
                                                                onChange={this.handleEditChange}
                                                                autoComplete="email"
                                                            />
                                                            <Form.Control.Feedback type="invalid">Please input a parent email address.</Form.Control.Feedback>

                                                        </Form.Group>
                                                        <Form.Group controlId="phone">
                                                            <Form.Label>Phone Number</Form.Label>
                                                            <Form.Control required
                                                                name="phone"
                                                                type="tel"
                                                                value={this.state.editedStudent.phone}
                                                                placeholder="Enter text"
                                                                onChange={this.handleEditChange}
                                                                autoComplete="tel-national"
                                                            />
                                                            <Form.Control.Feedback type="invalid">Please input a phone number.</Form.Control.Feedback>

                                                        </Form.Group>

                                                        <Form.Group controlId="note">
                                                            <Form.Label>Notes</Form.Label>
                                                            <Form.Control
                                                                name="note"
                                                                type="text"
                                                                value={this.state.editedStudent.note}
                                                                placeholder="Enter text"
                                                                onChange={this.handleEditChange}
                                                            />
                                                        </Form.Group>


                                                        <Form.Check disabled inline label="Completion Certificate Paid" type={"checkbox"} id={`addCompletionCertificate`} checked={this.state.editedStudent.certPaymentStatus} onChange={(e) => this.onCertificateChange(this.state.editedStudent.certPaymentStatus)} />                                <br></br>
                                                        <Button disabled={this.state.loading} type="submit">Update</Button>
                                                    </Form>
                                                </div> : null}

                                            </div> : <div></div>}

                                        </span>

                                    );
                                })}
                            </tbody>
                        </Table>
                        : null}

                    <br></br>
                </div>

            </div>
        );
    }
}
