import { API, Auth } from "aws-amplify";
import React from "react";
import { Accordion, Alert, Button, Card, Col, Row, Form, Table, ButtonToolbar } from 'react-bootstrap';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import './RegistrationForm.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import Select from 'react-select';
import crypto from 'crypto-js';
import { Locations } from "../components/contstants/Locations";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-day-picker/lib/style.css';

import TimePicker from 'react-bootstrap-time-picker';
import { timeFromInt, timeToInt } from 'time-number';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

export default class RoadLessonManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "0",
            daysWithLessons: [],
            selectedDays: [],
            loading: false,
            loadingDay: false,
            selectedDay: undefined,
            selectedLocation: "",
            startTime: "32400",
            endTime: "35100",
            slots: [],
            generateInstructorSlots: [],
            instructors: [],
            listViewMode: "all",
            listGroupViewMode: "instructor",
            showToast: false,
            toastMessage: "",
            lessonsToModify: new Set(),
            selectedInstructorToSwapTo: {},
            swapInstructorError: "",
            password: "ef92b778bafe771e89245b89ecbc08a44a4e166c06659911881f383d4473e94f",
            locations: ["West Roxbury", "Walpole"]
        };

        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndtimeTimeChange = this.handleEndtimeTimeChange.bind(this);
        this.handleSlotSave = this.handleSlotSave.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleSetLocationAndGetData = this.handleSetLocationAndGetData.bind(this);
        this.fetchSlotData = this.fetchSlotData.bind(this);
        this.deleteOldLesson = this.deleteOldLesson.bind(this);
        this.handleSelectedLessonChange = this.handleSelectedLessonChange.bind(this);
        this.handleSelectAllLessons = this.handleSelectAllLessons.bind(this);
    }

    fetchRoadTestInstructors() {
        const that = this;
        fetch('https://s3.amazonaws.com/publicdates/roadTestInstructors.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                if (typeof (data) === "undefined") {
                    that.setState({ instructors: [] });
                }
                else {
                    var decryptedInstructors = [];
                    for (let i = 0; i < data.length; i++) {
                        let instructor = data[i];
                        var decryptedName = crypto.AES.decrypt(instructor.resourceTitle, that.state.password).toString(crypto.enc.Utf8);
                        decryptedInstructors.push(
                            { resourceId: instructor.resourceId, resourceTitle: decryptedName, value: decryptedName, label: decryptedName }
                        );
                    }
                    that.setState({ instructors: decryptedInstructors });
                }
            });

    }

    componentDidMount() {
        //We should fetch the instructors and save their state here.
        this.fetchRoadTestInstructors();
    }

    async deleteOldLesson(studentDate, studentTimestamp, instructor, lessonDate, lessonLocationTime) {
        let data = {
            "mode": "deleteSpecificLessonFromRoadLessons",
            "student": {
                "date": studentDate,
                "timestamp": studentTimestamp,
                "instructor": instructor,
                "lessonDate": moment(lessonDate).format("YYYY-MM-DD"),
                "locationTime": lessonLocationTime

            }
        };

        this.setState({ loading: true });
        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: [data]
            ,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        console.log(options);
        API.post("APIGateway", "/modifystudentdata", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    that.handleSetLocationAndGetData(that.state.selectedLocation);
                }
                else {
                    console.log("No students found");
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }

    sortSlots(array, key) {
        if (array.length === 0) {
            return [];
        }
        return array.sort(function (a, b) {
            var x = timeToInt(a[key]); var y = timeToInt(b[key]);
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    async handleDayClick(day, { selected }) {
        this.setState({
            selectedDay: selected ? undefined : day
        });

        if (!selected) {
            this.handleSetLocationAndGetData(this.state.selectedLocation);
        }

    }
    handleSetLocationAndGetData = async (location) => {
        this.setState({ loadingDay: true, selectedLocation: location }, this.fetchSlotData);
    };
    handleSelectAllLessons() {
        let lessonsToModify = this.state.lessonsToModify;
        lessonsToModify.clear();
        this.state.slots.map((slot, index) => {
            return (
                slot.selectedInstructors.map((instructor, index) => {
                    return this.state.selectedLocation + "|" + slot.slotName + "|" + instructor.label;
                })
            );
        }).flatMap(string => string).map(string => lessonsToModify.add(string));
        this.setState({ lessonsToModify });
    }

    handleSelectedLessonChange(evt) {
        let lessonsToModify = this.state.lessonsToModify;
        let lessonId = evt.target.id;
        if (lessonsToModify.has(lessonId)) {
            lessonsToModify.delete(lessonId);
        } else {
            lessonsToModify.add(lessonId);
        }

        this.setState({ lessonsToModify });
    }

    handleSwapLessons = async () => {
        if (this.state.lessonsToModify.size == 0 || Object.keys(this.state.selectedInstructorToSwapTo) == 0) {
            console.log("Unable to swap");
        }
        let lessonsToSwap = [];
        this.state.slots.map((slot, index) => {
            return (
                slot.selectedInstructors.map((instructor, index) => {
                    let id = this.state.selectedLocation + "|" + slot.slotName + "|" + instructor.label;
                    if (this.state.lessonsToModify.has(id)) {
                        var lessonToSwap = {
                            "lessonDate": slot.date,
                            "locationTime": slot.locationTime,
                            "oldInstructor": instructor.label,
                            "newInstructor": this.state.selectedInstructorToSwapTo.label,
                            "oldResourceId": instructor.resourceId,
                            "newResourceId": this.state.selectedInstructorToSwapTo.resourceId,

                        };

                        if (slot.bookedStudents.hasOwnProperty(instructor.label)) {
                            var student = slot.bookedStudents[instructor.label];
                            Object.assign(lessonToSwap, { "studentTimestamp": student.timestamp });
                            Object.assign(lessonToSwap, { "studentDate": student.date });

                        }
                        lessonsToSwap.push(lessonToSwap);
                    }
                })
            );
        });
        this.setState({ loading: true, swapInstructorError: "" });
        const that = this;
        let session = await Auth.currentSession();
        let options = {
            body: lessonsToSwap, // replace this with attributes you need
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        try {
            let results = await API.post("APIGateway", "/swapInstructorLesson", options);
            that.setState({ loading: false, refreshStatus: "Successfully updated!", lessonsToModify: new Set(), selectedInstructorToSwapTo: {} });
            that.handleSetLocationAndGetData(that.state.selectedLocation);
        }
        catch (error) {
            console.log(error);
            if (error.response) {
                if (error.response.status === 401) {
                    that.setState({ loading: false, swapInstructorError: "Authentication Failed. Please log back in." });
                }
                else {
                    that.setState({ loading: false, swapInstructorError: "There was an error updating the lesson. Please copy this message and send it to your developer for debugging." + JSON.stringify(error.response.data.error) });
                }
            }
            else {
                that.setState({ loading: false, swapInstructorError: "Network error." });
            }

            return ([]);
        }

    };
    fetchSlotData = async () => {
        if (this.state.selectedDay) {
            let results = await this.handleGetSlotsbyDay(this.state.selectedDay);

            let slots = results.filter(x => x.selectedInstructors && x.selectedInstructors.length > 0); //Should we clear instructors without slots?
            slots = slots.sort((a, b) => (a.startTime > b.startTime) ? 1 : -1);
            console.log(slots);



            let startId = 0;
            let events = [];
            let selectedDay = moment(this.state.selectedDay).format("YYYY-MM-DD ");
            slots.forEach(slot => {

                slot.selectedInstructors.forEach(instructor => {
                    let data = {};

                    if (slot.bookedStudents.hasOwnProperty(instructor.label)) {
                        data =
                        {
                            id: startId,
                            title: 'Booked Lesson',
                            student: slot.bookedStudents[instructor.label].name,
                            start: new Date(selectedDay + slot.startTime),
                            end: new Date(selectedDay + slot.endTime),
                            resourceId: parseInt(instructor.value),
                            color: "green"
                        };
                        events.push(data);
                        startId++;
                    }
                    else {
                        data =
                        {
                            id: startId,
                            title: 'Open Lesson',
                            student: "Open Slot",
                            start: new Date(selectedDay + slot.startTime),
                            end: new Date(selectedDay + slot.endTime),
                            resourceId: parseInt(instructor.value),
                            color: "#265985"
                        };
                        events.push(data);
                        startId++;
                    }
                });
            });
            this.setState({
                loadingDay: false,
                slots: slots,
                events: events
            });
        }
    };

    handleGetSlotsbyDay = async (day) => {
        let date = day.toISOString().split('T')[0];

        let queryData = {
            "start": date
        };
        const that = this;
        let session = await Auth.currentSession();
        let options = {
            body: queryData, // replace this with attributes you need
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        try {
            let data = await API.post("APIGateway", "/getlessondata", options);
            let specificLocationData = data.filter(x => x.location === that.state.selectedLocation);
            that.setState({ loading: false, refreshStatus: "Successfully updated!" });
            return specificLocationData;
        }
        catch (error) {
            console.log(error);
            if (error.response) {
                if (error.response.status === 401) {
                    that.setState({ loading: false, dateError: "Authentication Failed. Please log back in." });
                }
                else {
                    that.setState({ loading: false, dateError: "There was an error saving your dates. Please copy this message and send it to your developer for debugging." + JSON.stringify(error.response) });
                }
            }
            else {
                that.setState({ loading: false, dateError: "Network error." });
            }

            return ([]);
        }

    };

    generateSlots = async event => {
        var selectedDate = this.state.selectedDay.toISOString().split('T')[0];
        var existingSlots = this.state.slots.map(x => x.slotName);
        var slots = [];

        var stateSlotNames = this.state.slots.map(x => x.slotName);

        var startIndex = parseInt(this.state.startTime);
        var endIndex = parseInt(this.state.endTime);

        for (var i = startIndex; i <= endIndex; i = i + 2700) {
            var slotName = timeFromInt(i, { format: 12 });
            var data = {};
            if (existingSlots.includes(slotName)) {//We already have a slot at this time.
                var position = stateSlotNames.indexOf(slotName);
                data = this.state.slots[position];
                //Add the new instructor if they aren't in there.
                if (this.state.generateInstructorSlots.length > 0) {
                    let existingNames = data.selectedInstructors.map(x => x.label);
                    for (let index = 0; index < this.state.generateInstructorSlots.length; index++) {
                        const instructor = this.state.generateInstructorSlots[index];
                        if (!existingNames.includes(instructor.label)) {
                            data.selectedInstructors.push(instructor);
                        }
                    }
                }
                //Since we added instructors, we should sort them..
                data.selectedInstructors = data.selectedInstructors.sort((a, b) => (a.label > b.label) ? 1 : -1);

            }
            else {
                data = {
                    date: selectedDate,
                    locationTime: this.state.selectedLocation + "|" + timeFromInt(i, { format: 12 }),
                    location: this.state.selectedLocation,
                    slotName: timeFromInt(i, { format: 12 }),
                    selectedInstructors: this.state.generateInstructorSlots ? this.state.generateInstructorSlots : [],
                    startTime: timeFromInt(i, { format: 24 }),
                    endTime: timeFromInt(i + 2700, { format: 24 }),
                    bookedStudents: {}
                };
            }
            slots.push(data);
        }
        console.log(slots);

        //We need to check if there are slots that were part of the original, and if they were not added, readd them.
        var missingOriginalsSlots = existingSlots.filter(e => !slots.map(x => x.slotName).includes(e));
        var missingOriginals = this.state.slots.filter(x => missingOriginalsSlots.includes(x.slotName));
        slots.concat(missingOriginals);

        this.setState({ slots: this.sortSlots(slots.concat(missingOriginals), 'slotName') });
    };

    generateTypicalSlots = async event => {

        var selectedDate = this.state.selectedDay.toISOString().split('T')[0];
        var existingSlots = this.state.slots.map(x => x.slotName);
        var slots = [];
        var stateSlotNames = this.state.slots.map(x => x.slotName);

        //9AM-11:15AM, 2:30PM-4:15
        let typicalRange = [[32400, 40500], [52200, 60300]];
        for (let index = 0; index < typicalRange.length; index++) {
            const ranges = typicalRange[index];
            const startRange = ranges[0];
            const endRange = ranges[1];
            for (var i = parseInt(startRange); i <= parseInt(endRange); i = i + 2700) {
                var slotName = timeFromInt(i, { format: 12 });
                var data = {};
                if (existingSlots.includes(slotName)) {//We already have a slot at this time.
                    var position = stateSlotNames.indexOf(slotName);
                    data = this.state.slots[position];
                    //Add the new instructor if they aren't in there.
                    if (this.state.generateInstructorSlots.length > 0) {
                        let existingNames = data.selectedInstructors.map(x => x.label);
                        for (let index = 0; index < this.state.generateInstructorSlots.length; index++) {
                            const instructor = this.state.generateInstructorSlots[index];
                            if (!existingNames.includes(instructor.label)) {
                                data.selectedInstructors.push(instructor);
                            }
                        }
                    }
                    //Since we added instructors, we should sort them..
                    data.selectedInstructors = data.selectedInstructors.sort((a, b) => (a.label > b.label) ? 1 : -1);

                }
                else {
                    data = {
                        date: selectedDate,
                        locationTime: this.state.selectedLocation + "|" + timeFromInt(i, { format: 12 }),
                        location: this.state.selectedLocation,
                        slotName: timeFromInt(i, { format: 12 }),
                        selectedInstructors: this.state.generateInstructorSlots ? this.state.generateInstructorSlots : [],
                        startTime: timeFromInt(i, { format: 24 }),
                        endTime: timeFromInt(i + 2700, { format: 24 }),
                        bookedStudents: {}
                    };
                }
                slots.push(data);
            }
        }
        console.log(slots);

        //We need to check if there are slots that were part of the original, and if they were not added, readd them.
        var missingOriginalsSlots = existingSlots.filter(e => !slots.map(x => x.slotName).includes(e));
        var missingOriginals = this.state.slots.filter(x => missingOriginalsSlots.includes(x.slotName));
        slots.concat(missingOriginals);
        //console.log(missingOriginals)

        this.setState({ slots: this.sortSlots(slots.concat(missingOriginals), 'slotName') });
    };

    handleSlotClear(index) {
        var localSlots = this.state.slots;
        let selectedSlot = localSlots[index];
        let bookedInstructors = Object.keys(selectedSlot.bookedStudents);
        if (bookedInstructors.length > 0) { //There are booked students.
            selectedSlot.selectedInstructors = selectedSlot.selectedInstructors.filter(x => bookedInstructors.includes(x.label));
            localSlots[index] = selectedSlot;
            this.setState({ slots: localSlots, toastType: "Warning", toastMessage: "Unable to fully clear slot because a student is still booked with the remaining instructor.", showToast: true });
        }
        else {
            localSlots[index].selectedInstructors = [];
            this.setState({ slots: localSlots });
        }
    }

    async handleSlotDelete(index) {
        let that = this;
        var localSlots = this.state.slots;
        let selectedSlot = localSlots[index];
        let bookedInstructors = Object.keys(selectedSlot.bookedStudents);
        console.log(bookedInstructors);
        if (bookedInstructors.length > 0) { //There are booked students.
            selectedSlot.selectedInstructors = selectedSlot.selectedInstructors.filter(x => bookedInstructors.includes(x.label));
            localSlots[index] = selectedSlot;
            this.setState({ slots: localSlots, toastType: "Warning", toastMessage: "Unable to fully delete slot because a student is still booked with the remaining instructor.", showToast: true });
            return;
        }
        else {
            let lessons = [];
            let input = {
                "mode": "delete",
                "lesson": {
                    "date": selectedSlot.date,
                    "locationTime": this.state.selectedLocation + "|" + selectedSlot.slotName
                }
            };
            lessons.push(input);
            let session = await Auth.currentSession();
            let options = {
                body: lessons,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': session.idToken.jwtToken
                }
            };
            API.post("APIGateway", "/modifylessondata ", options)
                .then(function (data) {
                    if (data.hasOwnProperty('type') && data.type === "success") {
                        that.setState({ slots: localSlots });
                    }
                    else {
                        //that.setState({ APIDateStudentResults: [] })
                        console.log("No students found");
                    }
                }).catch(function (error) {
                    console.log(error);
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                });

        }
        localSlots.splice(index, 1);
        this.setState({ slots: localSlots });
    }

    handleStartTimeChange(time) {
        console.log(time);     // <- prints "3600" if "01:00" is picked
        this.setState({ startTime: time, endTime: time + 600 });
    }
    handleEndtimeTimeChange(time) {
        console.log(time);     // <- prints "3600" if "01:00" is picked
        this.setState({ endTime: time });
    }

    handlePick = (selectedOption, index) => {
        var localSlots = this.state.slots;
        var selectedSlot = localSlots[index];
        var bookedInstructors = Object.keys(selectedSlot.bookedStudents);
        var newInstructors = [];
        if (selectedOption !== null) {
            newInstructors = selectedOption.map(x => x.label);
        }

        const bookedInstructorsKept = bookedInstructors.every(val => newInstructors.includes(val));
        if (bookedInstructorsKept) {
            selectedSlot.selectedInstructors = selectedOption;
            this.setState({ slots: localSlots });
        }
        else {
            this.setState({ slots: localSlots, toastType: "Warning", toastMessage: "A student is still booked with this instructor.", showToast: true });
        }
    };

    handleInstructorGeneratorPick = (selectedOption) => {
        console.log(selectedOption);
        this.setState({ generateInstructorSlots: selectedOption });
    };


    handleInstructorSwapPick = (selectedOption) => {
        console.log(selectedOption);
        this.setState({ selectedInstructorToSwapTo: selectedOption });
    };

    async handleSlotSave() {
        let that = this;
        this.setState({ loading: true });
        let selectedDate = this.state.selectedDay.toISOString().split('T')[0] + "T";
        let slots = this.state.slots;
        let events = [];
        let lessons = [];

        for (let index = 0; index < slots.length; index++) {
            const slot = slots[index];
            let input = {};
            if (slot.selectedInstructors && slot.selectedInstructors.length === 0) {
                input = {
                    "mode": "delete",
                    "lesson": slot
                };
            }
            else {
                input = {
                    "mode": "modify",
                    "lesson": slot
                };
            }
            lessons.push(input);
        }
        let session = await Auth.currentSession();
        let options = {
            body: lessons,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        };
        API.post("APIGateway", "/modifylessondata ", options)
            .then(function (data) {
                if (data.hasOwnProperty('type') && data.type === "success") {
                    that.setState({ loading: false, showToast: true, toastType: "Success", toastMessage: "Successfully updated!" }, that.fetchSlotData);
                    //console.log(data)
                }
                else {
                    that.setState({ loading: false, showToast: true, toastType: "Warning", toastMessage: "Authentication Failed. Please log back in." });
                    console.log("No students found");
                }
            }).catch(function (error) {
                that.setState({ loading: false });

                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });

        slots = this.state.slots.filter(x => x.selectedInstructors && x.selectedInstructors.length > 0); //Should we clear instructors without slots?

        let startId = 0;
        slots.forEach(slot => {
            slot.selectedInstructors.forEach(instructor => {
                let data = {};

                if (slot.bookedStudents.hasOwnProperty(instructor.label)) {
                    data =
                    {
                        id: startId,
                        title: 'Booked Lesson',
                        student: slot.bookedStudents[instructor.label].name,
                        start: new Date(selectedDate + slot.startTime),
                        end: new Date(selectedDate + slot.endTime),
                        resourceId: parseInt(instructor.value),
                        color: "green"
                    };
                    events.push(data);
                    startId++;
                }
                else {
                    data =
                    {
                        id: startId,
                        title: 'Open Lesson',
                        student: "Open Slot",
                        start: new Date(selectedDate + slot.startTime),
                        end: new Date(selectedDate + slot.endTime),
                        resourceId: parseInt(instructor.value),
                        color: "#265985"
                    };
                    events.push(data);
                    startId++;
                }
            });
        });
        this.setState({ events: events });

    }


    handleLessonModalChange(state, index) {
        console.log(state, index);
    }

    formatPhone(phone) {
        if (phone.length === 10) {
            return '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + '-' + phone.substr(6);
        }
        return phone;
    }

    renderInstructor(listViewMode, groupMode) {
        let slots = this.state.slots;
        let selectedDate = this.state.selectedDay.toISOString().split('T')[0] + "T";
        let instructorDivision = {};

        if (listViewMode === "booked") {
            slots = slots.filter(x => Object.keys(x.bookedStudents).length > 0);
        }
        if (listViewMode === "free") {
            slots = slots.filter(x => Object.keys(x.bookedStudents).length === 0);
        }

        if (slots.length === 0) {
            return (<div>No results found.</div>);
        }
        else {
            if (groupMode === "instructor") {
                slots.forEach(slot => {
                    slot.selectedInstructors.forEach(instructor => {
                        if (!instructorDivision.hasOwnProperty(instructor.label)) {
                            instructorDivision[instructor.label] = [];
                        }
                        let data = {};
                        if (slot.bookedStudents.hasOwnProperty(instructor.label)) {
                            data =
                            {
                                phone: slot.bookedStudents[instructor.label].phone,
                                name: slot.bookedStudents[instructor.label].name,
                                start: new Date(selectedDate + slot.startTime),
                                end: new Date(selectedDate + slot.endTime),
                                resourceId: parseInt(instructor.value)
                            };
                        }
                        else {
                            data =
                            {
                                phone: "Open Slot",
                                name: "Open Lesson",
                                start: new Date(selectedDate + slot.startTime),
                                end: new Date(selectedDate + slot.endTime),
                                resourceId: parseInt(instructor.value)
                            };

                        }
                        instructorDivision[instructor.label].push(data);
                    });
                });
                let instructorKeys = Object.keys(instructorDivision).sort((a, b) => (a > b) ? 1 : -1);
                if (instructorKeys.length > 0) {
                    return instructorKeys.map(instructor => {
                        let instructorSlots = instructorDivision[instructor];
                        if (instructorSlots && instructorSlots.length > 0) {
                            return (
                                <div key={instructor}>
                                    <div><strong>{instructor}</strong></div>
                                    {
                                        instructorSlots.map((instructorSlot, index) => {
                                            return (
                                                <Row key={index}>
                                                    <Col>
                                                        {instructorSlot.start.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                                    </Col>
                                                    <Col>
                                                        {instructorSlot.name}
                                                    </Col>
                                                    <Col>
                                                        {this.formatPhone(instructorSlot.phone)}
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    <br></br>
                                </div>
                            );
                        }
                        return null;
                    });
                }

            }
            else {
                return (
                    <div>
                        {slots.map(slot => {
                            return (
                                <div>
                                    <div><strong>{slot.slotName}</strong></div>
                                    {
                                        slot.selectedInstructors.map(instructor => {
                                            let instructorName = instructor.label;
                                            if (slot.bookedStudents.hasOwnProperty(instructorName)) {
                                                return (
                                                    <Row>
                                                        <Col>
                                                            {instructorName}
                                                        </Col>
                                                        <Col>
                                                            {slot.bookedStudents[instructorName].name}
                                                        </Col>
                                                        <Col>
                                                            {slot.bookedStudents[instructorName].phone}
                                                        </Col>
                                                    </Row>
                                                );
                                            }
                                            else {
                                                return (
                                                    <Row>
                                                        <Col>
                                                            {instructorName}
                                                        </Col>
                                                        <Col>
                                                            Open Lesson
                                                        </Col>
                                                        <Col>
                                                            Open Slot
                                                        </Col>
                                                    </Row>
                                                );
                                            }

                                        })} <br></br>
                                </div>
                            );

                        })}
                    </div>
                );

            }
        }
        return null;
    }

    renderClearStudents() {
        let slots = this.state.slots;
        slots = slots.filter(x => Object.keys(x.bookedStudents).length > 0);
        if (slots.length === 0) {
            return (<div>No booked students found.</div>);
        }
        return (
            <div>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Time Slot</th>
                            <th>Instructor</th>
                            <th>Road Test Date</th>
                            <th>Student Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {slots.map((slot, index) => {
                            return (
                                slot.selectedInstructors.map((instructor, index) => {
                                    let instructorName = instructor.label;
                                    let roadTestLessonDate = this.state.selectedDay;
                                    let roadTestDate = slot.bookedStudents[instructorName].date || "";

                                    let studentName = slot.bookedStudents[instructorName].name || "";
                                    let studentTime = slot.bookedStudents[instructorName].timestamp || "";

                                    return (
                                        <tr key={index}>
                                            <td>{slot.slotName}</td>
                                            <td>{instructorName}</td>
                                            <td>{roadTestDate}</td>
                                            <td>{studentName}</td>
                                            <td><Button variant="danger" onClick={() => this.deleteOldLesson(roadTestDate, studentTime, instructorName, roadTestLessonDate, this.state.selectedLocation + "|" + slot.slotName)}>Delete</Button></td>
                                        </tr>
                                    );
                                })
                            );
                        }


                        )}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderSwapStudents() {
        let slots = this.state.slots;
        if (slots.length === 0) {
            return (<div>No booked students found.</div>);
        }
        return (
            <div>
                <Button onClick={this.handleSelectAllLessons}>Select all lessons to be modified</Button>
                <br></br>
                <br></br>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Selected</th>
                            <th>Time Slot</th>
                            <th>Instructor</th>
                            <th>Road Test Date</th>
                            <th>Student Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {slots.map((slot, index) => {
                            return (
                                slot.selectedInstructors.map((instructor, index) => {
                                    let instructorName = instructor.label;
                                    let roadTestDate = "";
                                    let studentName = "";
                                    let studentTime = "";

                                    if (slot.bookedStudents.hasOwnProperty(instructorName)) {
                                        roadTestDate = slot.bookedStudents[instructorName].date;
                                        studentName = slot.bookedStudents[instructorName].name;
                                        studentTime = slot.bookedStudents[instructorName].timestamp;
                                    }

                                    var id = this.state.selectedLocation + "|" + slot.slotName + "|" + instructorName;


                                    return (
                                        <tr key={index}>
                                            <td> <Form.Check
                                                type="checkbox"
                                                id={id}
                                                checked={this.state.lessonsToModify.has(id)}
                                                onClick={this.handleSelectedLessonChange}
                                            />
                                            </td>
                                            <td>{slot.slotName}</td>
                                            <td>{instructorName}</td>
                                            <td>{roadTestDate}</td>
                                            <td>{studentName}</td>
                                        </tr>
                                    );
                                })
                            );
                        }


                        )}
                    </tbody>
                </Table>

                {this.state.lessonsToModify.size > 0 ? <div>
                    Select the instructor {this.state.lessonsToModify.size > 1 ? "these" : "this"} {this.state.lessonsToModify.size} lesson{this.state.lessonsToModify.size > 1 ? "s" : ""} should be swapped to
                    <Select
                        value={this.state.selectedInstructorToSwapTo}
                        onChange={(data) => this.handleInstructorSwapPick(data)}
                        options={this.state.instructors}
                        menuPlacement="top"
                    />


                    <Button disabled={this.state.loading} onClick={this.handleSwapLessons}>Swap selected lessons to selected instructor</Button>
                    <br></br>

                    {this.state.swapInstructorError}


                </div> : <div>Please select lessons to be modified.</div>}
            </div>
        );
    }


    renderStudent(slot) {
        let studentNames = [];

        for (const instructor in slot.bookedStudents) {
            if (slot.bookedStudents[instructor].hasOwnProperty("name")) {
                studentNames.push(slot.bookedStudents[instructor].name);
            }
        }

        if (studentNames.length > 0) {
            return studentNames.map(studentName => { return (<div>{studentName}</div>); });
        } else {
            return null;
        }
    }

    render() {
        const { selectedDay, showToast, toastType, toastMessage } = this.state;

        return (
            <div className="holder">
                <h3>Manage Road Test Lessons</h3>
                {showToast ?
                    <div>
                        {toastType === "Warning" ?
                            <Alert variant={"warning"} onClose={() => this.setState({ showToast: false })} dismissible style={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                zIndex: 1,
                                width: "40%"
                            }}>
                                <Alert.Heading>{toastType}</Alert.Heading>
                                {toastMessage}
                            </Alert> : null}
                        {toastType === "Success" ?
                            <Alert variant={"success"} onClose={() => this.setState({ showToast: false })} dismissible style={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                zIndex: 1,
                                width: "40%"
                            }}>
                                <Alert.Heading>{toastType}</Alert.Heading>
                                {toastMessage}
                            </Alert> : null}

                    </div> : null}



                <Accordion defaultActiveKey="0" activeKey={this.state.activeKey}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.setState({ activeKey: "0" })}>
                            {this.state.selectedDay ? <div>
                                {moment(this.state.selectedDay).format('LL')}


                                {this.state.selectedLocation.length > 0 ? " at " + this.state.selectedLocation : ""}
                            </div> : "Select Day"}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {selectedDay ? null : <div>Please select a day you would like to view/generate lesson slots for.</div>}
                                <div>
                                    <DayPicker
                                        numberOfMonths={2}
                                        selectedDays={selectedDay}
                                        showOutsideDays
                                        onDayClick={this.handleDayClick}
                                    />

                                </div>
                                {selectedDay ?
                                    <div>
                                        {this.state.loadingDay ?
                                            <div>Please wait. Checking if existing lesson slots exist.</div> :
                                            <div>
                                                <hr></hr>
                                                {this.state.selectedLocation.length > 0 ? <div>Now viewing lessons for: {this.state.selectedLocation}</div> : <div>Please select the lesson location</div>}
                                                {this.state.locations.map((location, index) => {
                                                    return (
                                                        <Button disabled={this.state.selectedLocation === location} onClick={() => this.handleSetLocationAndGetData(location)}>{location}</Button>
                                                    );
                                                })}

                                                {this.state.selectedLocation.length > 0 ?
                                                    <div>
                                                        <hr></hr>
                                                        <div>
                                                            <br></br>
                                                            <div>Generate/View Slots for {moment(selectedDay).format("dddd, MMMM Do YYYY")}</div>
                                                            <br></br>
                                                            <Row>
                                                                <Col>
                                                                    <TimePicker start="5:00" end="21:00" step={15} onChange={this.handleStartTimeChange} value={this.state.startTime} />
                                                                </Col>
                                                                <Col sm={1}>to</Col>
                                                                <Col>
                                                                    <TimePicker start={timeFromInt(parseInt(this.state.startTime) + 2700)} end="21:00" step={45} onChange={this.handleEndtimeTimeChange} value={this.state.endTime} />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    With Instructor (Optional)
                                                                </Col>
                                                                <Col>
                                                                    <Select
                                                                        value={this.state.generateInstructorSlots}
                                                                        isMulti
                                                                        onChange={(data) => this.handleInstructorGeneratorPick(data)}
                                                                        options={this.state.instructors}
                                                                        menuPlacement="auto"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Button className="butPadding" disabled={this.state.loading} onClick={this.generateSlots}>Generate Slots</Button>
                                                            <hr></hr>
                                                            <Button className="butPadding" disabled={this.state.loading} onClick={this.generateTypicalSlots}>Insert Typical Daily Slots</Button>
                                                        </div>
                                                        <br></br>
                                                        <div>
                                                            <Table striped bordered hover responsive size="sm">
                                                                <thead>
                                                                    <tr className="d-flex">
                                                                        <th className="col-2">Slot Time</th>
                                                                        <th className="col-4">Instructors</th>
                                                                        <th className="col-4">Actions</th>
                                                                        <th className="col-2">Students</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.slots.map((x, index) => {
                                                                        return (
                                                                            <tr key={index} className="d-flex">
                                                                                <td className="col-2">{x.slotName}</td>
                                                                                <td className="col-4">
                                                                                    <Select
                                                                                        value={this.state.slots[index].selectedInstructors}
                                                                                        isMulti
                                                                                        onChange={(data) => this.handlePick(data, index)}
                                                                                        options={this.state.instructors.map(function (x) { return { value: x.value, label: x.label }; })}
                                                                                        menuPlacement="auto"
                                                                                    />
                                                                                </td>
                                                                                <td className="col-4">
                                                                                    <Button disabled={this.state.loading} onClick={() => this.handleSlotClear(index)} size="sm">Clear Slot</Button>
                                                                                    <Button disabled={this.state.loading} onClick={() => this.handleSlotDelete(index)} size="sm">Delete Slot</Button>
                                                                                </td>
                                                                                <td className="col-2">
                                                                                    {this.renderStudent(x)}
                                                                                </td>

                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </Table>

                                                            <Button variant="primary" className="butPadding" disabled={this.state.loading} onClick={this.handleSlotSave}> Save Slots for {this.state.selectedLocation} </Button>
                                                        </div>

                                                    </div> : null}


                                            </div>
                                        }


                                    </div> :
                                    null
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={() => this.setState({ activeKey: "3" })}>
                            Clear Lessons
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                {this.state.activeKey === "3" ?
                                    <div>
                                        {this.renderClearStudents()}
                                    </div> : null

                                }

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={() => this.setState({ activeKey: "4" })}>
                            Swap Instructors on Lessons
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>
                                {this.state.activeKey === "4" ?
                                    <div>
                                        {this.renderSwapStudents()}
                                    </div> : null

                                }

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => this.setState({ activeKey: "1" })}>
                            View Lessons (As List)
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                {this.state.activeKey === "1" ?
                                    <div>
                                        <ButtonToolbar>
                                            <Button disabled={this.state.listViewMode === "all"} onClick={() => this.setState({ listViewMode: "all" })} variant="outline-primary">View All</Button>
                                            <Button disabled={this.state.listViewMode === "booked"} onClick={() => this.setState({ listViewMode: "booked" })} variant="outline-primary">View Only Booked</Button>
                                            <Button disabled={this.state.listViewMode === "free"} onClick={() => this.setState({ listViewMode: "free" })} variant="outline-primary">View Only Free</Button>
                                        </ButtonToolbar>
                                        <ButtonToolbar>
                                            <Button disabled={this.state.listGroupViewMode === "instructor"} onClick={() => this.setState({ listGroupViewMode: "instructor" })} variant="outline-primary">Group by Instructor</Button>
                                            <Button disabled={this.state.listGroupViewMode === "time"} onClick={() => this.setState({ listGroupViewMode: "time" })} variant="outline-primary">Group by Time</Button>
                                        </ButtonToolbar>

                                        {selectedDay ?
                                            <div> Viewing Lessons for {moment(selectedDay).format("dddd, MMMM Do YYYY")} at {this.state.selectedLocation}
                                                <br></br>
                                                {this.renderInstructor(this.state.listViewMode, this.state.listGroupViewMode)}
                                            </div>
                                            : null}

                                    </div> : null

                                }

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>






                <br></br>
                <div>


                </div>

                <div>


                </div>

            </div>
        );
    }
}
